// Store the original fetch function
const originalFetch = window.fetch;

// Override the global fetch function
window.fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
  // Apply conditional headers to all requests
  const defaultHeaders =
    import.meta.env.VITE_VERCEL_SKEW_PROTECTION_ENABLED === "1"
      ? { "x-deployment-id": import.meta.env.VITE_VERCEL_DEPLOYMENT_ID }
      : {};

  // Merge with any custom headers passed to the function
  const headers = {
    ...defaultHeaders,
    ...(init?.headers || {}),
  } as HeadersInit;

  // Return original fetch with merged options
  return originalFetch(input, {
    ...init,
    headers: headers,
  });
};
